import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { GET } from "../tools/fetch";
import { Backdrop, CircularProgress } from "@mui/material";
import TeamLogo from "../teams/Logo";
import { useNavigate } from "react-router-dom";

const TabelleDetail = () => {
  const [loading, setLoading] = useState();
  const [tabelle, setTabelle] = useState();
  const [POgames, setPOGames] = useState();
  const [vf, setVf] = useState(true);
  const [hf, setHf] = useState(true);
  const [f, setF] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const load = async () => {
    try {
      setLoading(true);
      const load = await GET("/spiel/tabelle");
      setTabelle(load.tabelle);
      const games = await GET("/spiel/all?playoff=1");
      setPOGames(games.games);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const TeamByPlace = (platz) => {
    const team = tabelle.find((team) => team.place === platz);
    return team.id;
  };

  const GameByTyp = (typ, number) => {
    const game = POgames.find(
      (game) => game.po_typ === typ && game.po_number === number
    );
    return game;
  };
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <Container className="p-0 mt-2">
      <Table responsive size="sm" className="text-white text-center g-0">
        <thead>
          <tr>
            <th>#</th>
            <th>Team</th>
            <th>{isMobile ? "Sp." : "Spiele"}</th>
            <th>{isMobile ? "Pkt." : "Punkte"}</th>
            <th>{isMobile ? "T" : "Tore"}</th>
            <th>{isMobile ? "GT" : "Gegentore"}</th>
            <th>{isMobile ? "Diff." : "Differenz"}</th>
          </tr>
        </thead>
        <tbody>
          {loading === false &&
            tabelle.map((team, id) => {
              return (
                <tr key={id}>
                  <td>
                    <div
                      className="rounded"
                      style={{
                        backgroundColor:
                          team.place === 1
                            ? "gold"
                            : team.place === 2
                            ? "silver"
                            : team.place === 3
                            ? "brown"
                            : null,
                      }}
                    >
                      {team.place}
                    </div>
                  </td>
                  <td>{!isMobile ? team.name : team.short}</td>
                  <td>{team.GAMES}</td>
                  <td>{team.points}</td>
                  <td>{team.G}</td>
                  <td>{team.GA}</td>
                  <td>{team.TD}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      {/*Playoff-Picture*/}

      {loading === false && (
        <>
          <div className="vf">
            <Row onClick={() => setVf(!vf)}>
              <Col
                style={{ cursor: "pointer" }}
                className="pt-1 pb-1 text-center rounded mb-2 col-6 offset-3 bg-transparent border border-white"
              >
                Viertelfinale
              </Col>
            </Row>
            <Row className={vf ? "" : "d-none"}>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="d-flex justify-content-between p-1">
                    <div>{isMobile ? "1. VF" : "1. Viertelfinale"}</div>
                    <div>Best of 3</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={TeamByPlace(2)}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={TeamByPlace(5)}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            {GameByTyp("VF1", 1).bericht === 1 ? (
                              <div
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF1", 1).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF1", 1).score_home +
                                    ":" +
                                    GameByTyp("VF1", 1).score_away}
                                </u>
                              </div>
                            ) : (
                              "-:-"
                            )}
                          </div>
                          {" / "}
                          <div>
                            {GameByTyp("VF1", 2).bericht === 1 ? (
                              <div
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF1", 2).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF1", 2).score_home +
                                    ":" +
                                    GameByTyp("VF1", 2).score_away}
                                </u>
                              </div>
                            ) : (
                              "-:-"
                            )}
                          </div>
                          <div>
                            {GameByTyp("VF1", 3) ? (
                              GameByTyp("VF1", 3).bericht === 1 ? (
                                <div
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("VF1", 3).id}`)
                                  }
                                >
                                  {" / "}
                                  <u>
                                    {GameByTyp("VF1", 3).score_home +
                                      " : " +
                                      GameByTyp("VF1", 3).score_away}
                                    {GameByTyp("VF1", 3).wertung === 1 && (
                                      <span>*</span>
                                    )}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo detail={true} team={TeamByPlace(2)} />
                        </div>
                        <div className="d-block">
                          <div className="h5">
                            {GameByTyp("VF1", 1).bericht === 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF1", 1).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF1", 1).score_home +
                                    " : " +
                                    GameByTyp("VF1", 1).score_away}
                                </u>
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("VF1", 2).bericht === 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF1", 2).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF1", 2).score_home +
                                    " : " +
                                    GameByTyp("VF1", 2).score_away}
                                </u>
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("VF1", 3) ? (
                              GameByTyp("VF1", 3).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("VF1", 3).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("VF1", 3).score_home +
                                      " : " +
                                      GameByTyp("VF1", 3).score_away}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div>
                          <TeamLogo detail={true} team={TeamByPlace(5)} />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="d-flex justify-content-between p-1">
                    <div>{isMobile ? "2. VF" : "2. Viertelfinale"}</div>
                    <div>Best of 3</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={TeamByPlace(3)}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={TeamByPlace(4)}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            {GameByTyp("VF2", 1).bericht === 1 ? (
                              <div
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF2", 1).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF2", 1).score_home +
                                    ":" +
                                    GameByTyp("VF2", 1).score_away}
                                </u>
                              </div>
                            ) : (
                              "-:-"
                            )}
                          </div>
                          {" / "}
                          <div>
                            {GameByTyp("VF2", 2).bericht === 1 ? (
                              <div
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF2", 2).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF2", 2).score_home +
                                    ":" +
                                    GameByTyp("VF2", 2).score_away}
                                </u>
                              </div>
                            ) : (
                              "-:-"
                            )}
                          </div>
                          <div>
                            {GameByTyp("VF2", 3) ? (
                              GameByTyp("VF2", 3).bericht === 1 ? (
                                <div
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("VF2", 3).id}`)
                                  }
                                >
                                  {" / "}
                                  <u>
                                    {GameByTyp("VF2", 3).score_home +
                                      ":" +
                                      GameByTyp("VF2", 3).score_away}
                                    {GameByTyp("VF2", 3).wertung === 1 && (
                                      <span>*</span>
                                    )}
                                  </u>
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo detail={true} team={TeamByPlace(3)} />
                        </div>
                        <div className="d-block">
                          <div className="h5">
                            {GameByTyp("VF2", 1).bericht === 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF2", 1).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF2", 1).score_home +
                                    " : " +
                                    GameByTyp("VF2", 1).score_away}
                                </u>
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("VF2", 2).bericht === 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("VF2", 2).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("VF2", 2).score_home +
                                    " : " +
                                    GameByTyp("VF2", 2).score_away}
                                </u>
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("VF2", 3) ? (
                              GameByTyp("VF2", 3).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("VF2", 3).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("VF2", 3).score_home +
                                      " : " +
                                      GameByTyp("VF2", 3).score_away}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div>
                          <TeamLogo detail={true} team={TeamByPlace(4)} />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className={vf ? "pt-2" : "d-none"}>
              <Col xs="6" className="col-6 offset-3">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="text-center p-1">
                    Letzte Chance
                    <div className="small">(Duell der Verlierer)</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <TeamLogo
                            detail={!isMobile}
                            team={GameByTyp("LC", 1).home_team.id}
                          />
                          <div className="h5 align-self-center"> - </div>
                          <TeamLogo
                            detail={!isMobile}
                            team={GameByTyp("LC", 1).away_team.id}
                          />
                        </div>
                        <div className="text-center">
                          {GameByTyp("LC", 1).bericht === 1 ? (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/game/${GameByTyp("LC", 1).id}`)
                              }
                            >
                              <u>
                                {GameByTyp("LC", 1).score_home +
                                  " : " +
                                  GameByTyp("LC", 1).score_away}
                              </u>
                            </div>
                          ) : (
                            "- : -"
                          )}
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <TeamLogo
                          detail={!isMobile}
                          team={GameByTyp("LC", 1).home_team.id}
                        />
                        <div className="d-block align-self-center">
                          <div className="h5">
                            {GameByTyp("LC", 1).bericht === 1 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/game/${GameByTyp("LC", 1).id}`)
                                }
                              >
                                <u>
                                  {GameByTyp("LC", 1).score_home +
                                    " : " +
                                    GameByTyp("LC", 1).score_away}
                                </u>
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                        </div>
                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("LC", 1).away_team.id}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-3 hf">
            <Row onClick={() => setHf(!hf)}>
              <Col
                style={{ cursor: "pointer" }}
                className="pt-1 pb-1 text-center rounded mb-2 col-6 offset-3 bg-transparent border border-white"
              >
                Halbfinale
              </Col>
            </Row>
            <Row className={hf ? "" : "d-none"}>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="d-flex justify-content-between p-1">
                    <div>{isMobile ? "1. HF" : "1. Halbfinale"}</div>
                    <div>Best of 3</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={TeamByPlace(1)}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("HF1", 1).away_team.id}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            {GameByTyp("HF1", 1) ? (
                              GameByTyp("HF1", 1).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 1).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("HF1", 1).score_home +
                                      ":" +
                                      GameByTyp("HF1", 1).score_away}
                                  </u>
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          {" / "}
                          <div>
                            {GameByTyp("HF1", 2) ? (
                              GameByTyp("HF1", 2).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 2).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("HF1", 2).score_home +
                                      ":" +
                                      GameByTyp("HF1", 2).score_away}
                                  </u>
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {GameByTyp("HF1", 3) ? (
                              GameByTyp("HF1", 3).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 3).id}`)
                                  }
                                >
                                  {" / "}
                                  <u>
                                    {GameByTyp("HF1", 3).score_home +
                                      " : " +
                                      GameByTyp("HF1", 3).score_away}
                                    {GameByTyp("HF1", 3).wertung === 1 && (
                                      <span>*</span>
                                    )}
                                  </u>
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo detail={true} team={TeamByPlace(1)} />
                        </div>
                        <div className="d-block">
                          <div className="h5">
                            {GameByTyp("HF1", 1) ? (
                              GameByTyp("HF1", 1).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 1).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("HF1", 1).score_home +
                                      " : " +
                                      GameByTyp("HF1", 1).score_away}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("HF1", 2) ? (
                              GameByTyp("HF1", 2).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 2).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("HF1", 2).score_home +
                                      " : " +
                                      GameByTyp("HF1", 2).score_away}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("HF1", 3) ? (
                              GameByTyp("HF1", 3).bericht === 1 ? (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/game/${GameByTyp("HF1", 3).id}`)
                                  }
                                >
                                  <u>
                                    {GameByTyp("HF1", 3).score_home +
                                      " : " +
                                      GameByTyp("HF1", 3).score_away}
                                  </u>
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("HF1", 1).away_team.id}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="d-flex justify-content-between p-1">
                    <div>{isMobile ? "2. HF" : "2. Halbfinale"}</div>
                    <div>Best of 3</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("HF2", 1).home_team.id}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("HF2", 1).away_team.id}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            {GameByTyp("HF2", 1) ? (
                              GameByTyp("HF2", 1).bericht === 1 ? (
                                <div>
                                  {GameByTyp("HF2", 1).score_home +
                                    ":" +
                                    GameByTyp("HF2", 1).score_away}
                                  {GameByTyp("HF2", 1).wertung === 1 && (
                                    <span>*</span>
                                  )}
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          {" / "}
                          <div>
                            {GameByTyp("HF2", 2) ? (
                              GameByTyp("HF2", 2).bericht === 1 ? (
                                <div>
                                  {GameByTyp("HF2", 2).score_home +
                                    ":" +
                                    GameByTyp("HF2", 2).score_away}
                                  {GameByTyp("HF2", 2).wertung === 1 && (
                                    <span>*</span>
                                  )}
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            {GameByTyp("HF2", 3) ? (
                              GameByTyp("HF2", 3).bericht === 1 ? (
                                <div>
                                  {" / "}
                                  {GameByTyp("HF2", 3).score_home +
                                    ":" +
                                    GameByTyp("HF2", 3).score_away}
                                  {GameByTyp("HF2", 3).wertung === 1 && (
                                    <span>*</span>
                                  )}
                                </div>
                              ) : (
                                "-:-"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("HF2", 1).home_team.id}
                          />
                        </div>
                        <div className="d-block">
                          <div className="h5">
                            {GameByTyp("HF2", 1) ? (
                              GameByTyp("HF2", 1).bericht === 1 ? (
                                <div>
                                  {GameByTyp("HF2", 1).score_home +
                                    " : " +
                                    GameByTyp("HF2", 1).score_away}
                                  {GameByTyp("HF2", 1).wertung === 1 && (
                                    <span>*</span>
                                  )}
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("HF2", 2) ? (
                              GameByTyp("HF2", 2).bericht === 1 ? (
                                <div>
                                  {GameByTyp("HF2", 2).score_home +
                                    " : " +
                                    GameByTyp("HF2", 2).score_away}
                                  {GameByTyp("HF2", 2).wertung === 1 && (
                                    <span>*</span>
                                  )}
                                </div>
                              ) : (
                                "- : -"
                              )
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="h5">
                            {GameByTyp("HF2", 3)
                              ? GameByTyp("HF2", 3).bericht === 1
                                ? GameByTyp("HF2", 3).score_home +
                                  " : " +
                                  GameByTyp("HF2", 3).score_away
                                : "- : -"
                              : ""}
                          </div>
                        </div>

                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("HF2", 1).away_team.id}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="mt-3 platzierung">
            <Row onClick={() => setF(!f)}>
              <Col
                style={{ cursor: "pointer" }}
                className="pt-1 pb-1 text-center rounded mb-2 col-6 offset-3 bg-transparent border border-white"
              >
                Finalspiele
              </Col>
            </Row>
            <Row className={f ? "" : "d-none"}>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="text-center p-1">
                    <div style={{ color: "gold" }}>
                      <b>Finale</b>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("P1", 1).home_team.id}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("P1", 1).away_team.id}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <div>
                            {GameByTyp("P1", 1).bericht === 1
                              ? GameByTyp("P1", 1).score_home +
                                " : " +
                                GameByTyp("P1", 1).score_away
                              : "- : -"}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("P1", 1).home_team.id}
                          />
                        </div>
                        <div className="d-block align-self-center">
                          <div className="h5">
                            {GameByTyp("P1", 1).bericht === 1
                              ? GameByTyp("P1", 1).score_home +
                                " : " +
                                GameByTyp("P1", 1).score_away
                              : "- : -"}
                          </div>
                        </div>

                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("P1", 1).away_team.id}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs="6">
                <Card
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                  className=" border border-white"
                >
                  <Card.Header className="text-center p-1">
                    <div>Spiel um Platz 3</div>
                  </Card.Header>
                  <Card.Body>
                    {isMobile && (
                      <>
                        <div className="d-flex justify-content-between">
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("P3", 1).home_team.id}
                            />
                          </div>
                          <div className="h5 align-self-center"> - </div>
                          <div>
                            <TeamLogo
                              detail={!isMobile}
                              team={GameByTyp("P3", 1).away_team.id}
                            />
                          </div>
                        </div>
                        <div className="text-center">
                          <div>
                            {GameByTyp("P3", 1).bericht === 1 ? (
                              <div>
                                {GameByTyp("P3", 1).score_home +
                                  " : " +
                                  GameByTyp("P3", 1).score_away}
                                {GameByTyp("P3", 1).wertung === 1 && (
                                  <span>*</span>
                                )}
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {!isMobile && (
                      <div className="d-flex justify-content-between">
                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("P3", 1).home_team.id}
                          />
                        </div>
                        <div className="d-block align-self-center">
                          <div className="h5">
                            {GameByTyp("P3", 1).bericht === 1 ? (
                              <div>
                                {GameByTyp("P3", 1).score_home +
                                  " : " +
                                  GameByTyp("P3", 1).score_away}
                                {GameByTyp("P3", 1).wertung === 1 && (
                                  <span>*</span>
                                )}
                              </div>
                            ) : (
                              "- : -"
                            )}
                          </div>
                        </div>

                        <div>
                          <TeamLogo
                            detail={true}
                            team={GameByTyp("P3", 1).away_team.id}
                          />
                        </div>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default TabelleDetail;
