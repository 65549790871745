import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trophy } from "lucide-react";

const Top3 = (props) => {
  const teams = useSelector((state) => state.teams.teams);
  const team = teams.find((team) => team.id === props.team);
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const isMedium = width > 768 && width <= 991;
  const navigate = useNavigate();

  // Get trophy color based on position
  const getTrophyColor = (position) => {
    switch (position) {
      case 1:
        return "text-yellow-400";
      case 2:
        return "text-gray-400";
      case 3:
        return "text-amber-700";
      default:
        return "";
    }
  };

  // Mobile layout uses a simple stacked approach
  if (isMobile) {
    return (
      <div
        className="bg-gray-800 border border-gray-700 rounded-lg shadow-xl p-2 mb-2 mx-auto cursor-pointer hover:scale-105 transition-transform duration-300"
        style={{
          width: "calc(100% - 16px)",
          maxWidth: "320px",
          marginBottom: props.position === 3 ? "0" : "16px",
        }}
        onClick={() => {
          // Handler for click, e.g., to show player details
          if (props.player && props.player.player && props.player.player.id) {
            props.onClick && props.onClick(props.player.player.id);
          }
        }}
      >
        <div className="flex items-center justify-between mb-2">
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.player.nachname}, {props.player.player.vorname}
            </div>
            <div className="text-sm text-gray-300">{team.short}</div>
          </div>
          <div className="flex items-center">
            <Trophy
              className={`w-6 h-6 mr-1 ${getTrophyColor(props.position)}`}
            />
            <span className="text-lg font-bold text-white">
              #{props.position}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 text-center mt-3">
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.goals}
            </div>
            <div className="text-xs text-gray-400">Goals</div>
          </div>
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.assists}
            </div>
            <div className="text-xs text-gray-400">Assists</div>
          </div>
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.points}
            </div>
            <div className="text-xs text-gray-400">Points</div>
          </div>
        </div>

        <div className="mt-2 text-center">
          <div className="text-xs text-gray-400">
            Games: {props.player.games}
          </div>
        </div>
      </div>
    );
  }

  // Desktop layout uses podium-style positioning
  return (
    <div
      className="bg-gray-800 border border-gray-700 rounded-lg shadow-xl p-2 transition-transform duration-300 cursor-pointer"
      style={{
        position: "absolute",
        ...(props.position === 1 && {
          left: "50%",
          transform: "translateX(-50%)",
          top: "0",
          width: isMedium ? "240px" : "260px",
          zIndex: 3,
        }),
        ...(props.position === 2 && {
          left: isMedium ? "0%" : "5%",
          top: isMedium ? "110px" : "80px",
          width: isMedium ? "230px" : "260px",
          zIndex: 2,
        }),
        ...(props.position === 3 && {
          right: isMedium ? "0%" : "5%",
          top: isMedium ? "110px" : "80px",
          width: isMedium ? "230px" : "260px",
          zIndex: 1,
        }),
      }}
      onClick={() => {
        // Handler for click, e.g., to show player details
        if (props.player && props.player.player && props.player.player.id) {
          props.onClick && props.onClick(props.player.player.id);
        }
      }}
    >
      <div className="relative">
        <div className="absolute top-1 right-2">
          <Trophy className={`w-6 h-6 ${getTrophyColor(props.position)}`} />
        </div>

        <div className="mt-2">
          <div className="text-lg font-bold text-white">
            {props.player.player.nachname}, {props.player.player.vorname}
          </div>
          <div className="text-sm text-gray-300">{team.short}</div>
        </div>

        <div className="mt-2 grid grid-cols-3 gap-2 text-center">
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.goals}
            </div>
            <div className="text-xs text-gray-400">Goals</div>
          </div>
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.assists}
            </div>
            <div className="text-xs text-gray-400">Assists</div>
          </div>
          <div>
            <div className="text-lg font-bold text-white">
              {props.player.points}
            </div>
            <div className="text-xs text-gray-400">Points</div>
          </div>
        </div>

        <div className="mt-2 text-center">
          <div className="text-xs text-gray-400">
            Games: {props.player.games}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top3;
